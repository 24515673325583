<template>
  <article id="website-cookie-policy">
    <h3>Cookies Policy for this website</h3>

    <p class="last-update text-muted">Last updated: March 14, 2022</p>

    <p>
      This website uses cookies for anonymous & aggregate statistics (Google
      Analytics), multimedia content playing (Youtube), and for providing user
      centric security when dealing with payment session (Stripe).
    </p>

    <p>
      A cookie is a small piece of data that a website asks your browser to
      store on your computer or mobile device. The cookie allows the web to
      "remember" your actions or preferences over time. <br />
      The majority of browsers support cookies, but users can set their browsers
      to decline them or use tools as the Stardust Cookie Cutter to manage and
      delete them whenever they like. Search for instructions on how to get
      control over cookies in your browser.
    </p>

    <p>
      Cookies do not typically contain any information that personally
      identifies a user. Some great resources to read about cookies are
      <a href="https://www.allaboutcookies.org/">allaboutcookies.org</a>.
    </p>

    <p>
      We do not store sensitive personal information, such as mailing addresses,
      account passwords, etc. in the cookies we use. This website also doesn’t
      use any persistent tracking cookies so we are not obliged to allow opting
      out of these cookies under the current EU regulations.
    </p>

    <h4>Contact us</h4>
    <p>
      If you have any questions about this Cookie Policy, You can contact us by
      email: <a href="mailto:hey@strdst.org">hey@strdst.org</a>.
    </p>
    <p class="text-muted">
      Stardust Network OÜ, reg.
      <a
        href="https://ariregister.rik.ee/eng/company/16032321/Stardust-Network-OÜ"
        target="_blank"
        >16032321</a
      >
      , Narva mnt 26, Tallinn, 10120.
    </p>
  </article>
</template>

<script>
export default {};
</script>

<style>
</style>