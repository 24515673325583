<template >

  <!-- background particles -->
  <Particles id="tsparticles" style="
      position: fixed;
      top: 0;
      left: 0;
      z-index: -100;
      width: 100%;
      height: 100%;
    " :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="{
      background: {
        color: {
          value: '',
        },
      },
      fpsLimit: 50,
      particles: {
        color: {
          value: '#e8ae1b',
        },
        links: {
          color: '#e8ae1b',
          distance: 180,
          enable: true,
          opacity: 0.8,
          width: 2,
        },
        collisions: {
          enable: false,
        },
        move: {
          direction: 'none',
          enable: true,
          outMode: 'bounce',
          random: false,
          speed: 0.1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            value_area: 60,
          },
          value: 4,
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: 'circle',
        },
        size: {
          random: false,
          value: 10,
        },
      },
      detectRetina: true,
    }" />

  <StardustTitle />

  <!-- dynamic content view -->
  <router-view />

  <!-- footer/more section -->
  <More />

</template>

<script>
import More from "./components/More.vue";
import StardustTitle from "./components/StardustTitle.vue";

export default {
  name: "App",
  components: {
    More,
    StardustTitle
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap");

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background-color: #182226 !important;
}

#app {
  font-family: Raleway, Inter, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fefefe;
}


@media (max-width: 1000px) {
  .container {
    max-width: 970px;
  }
}

hr {
  border: 1px solid rgba(254, 254, 254);
  border-radius: 5px;
  opacity: 0.05;
}

h1 {
  text-align: center;
  font-weight: 700;
  margin: 50px 0;
}


a {
  color: #e8ae1b;
}

a:hover {
  color: #edbf4a;
}

.highlight {
  color: #e8ae1b;
}

.stardust-title {
  color: #e8ae1b;
  font-family: Raleway;
  font-weight: 600;
  -webkit-filter: drop-shadow(0px 0px 6px #182226);
  filter: drop-shadow(0px 0px 6px #182226);
}

h4.sectionTitle {
  font-weight: 600;
}

/* Router styles */
#nav a {
  color: #666f70;
  text-decoration: none;
}

#nav a:hover {
  color: #fefefe;
}

#nav a.router-link-exact-active {
  color: #e8ae1b;
  text-decoration: none;
}

/* Bootstrap overwrites */
.btn-outline-dark {
  background-color: #182226;
  color: #e8ae1b;
  font-size: 1.3rem;
  padding: 1rem 1.3rem 1rem 1.3rem;
  border-radius: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-yellow {
  background-color: #e8ae1b;
  color: white;
  font-size: 20px;
  text-decoration: none;
  padding: 13px 30px;
  font-weight: 600;
  border-radius: 150px;
  height: 55px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-filter: drop-shadow(0px 0px 6px #182226);
  filter: drop-shadow(0px 0px 6px #182226);
}

.btn-yellow:hover {
  color: white;
  filter: brightness(105%);
}

.btn-secondary {
  color: white;
  font-size: 20px;
  text-decoration: none;
  padding: 13px 30px;
  font-weight: 600;
  border-radius: 150px;
  height: 55px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-filter: drop-shadow(0px 0px 6px #182226);
  filter: drop-shadow(0px 0px 6px #182226);
}

.btn-outline-dark:hover,
.btn-outline-dark:active,
.btn-outline-dark:visited {
  color: #e8ae1b;
}

.btn-light {
  border-radius: 20px;
  border: none;
  color: #fefefe;
  background-color: #666f70;
}

.btn-light:hover,
.btn-light:active,
.btn-light:visited,
.btn-light:focus {
  border: none;
  color: #fefefe;
  background-color: #818f91;
}

.btn-light.btn-donate {
  padding: 10px 20px;
  background-color: #e8ae1b;
}

.btn-light.btn-donate:hover,
.btn-light.btn-donate:active,
.btn-light.btn-donate:focus {
  background-color: #f3ce70;
  color: #fefefe;
}

.btn-light.btn-donate:visited {
  background-color: #e8ae1b;
  color: #fefefe;
}

.card {
  display: inline-block;
  border: 0;
  margin: 10px;
  padding: 0.2em;
  background-color: rgba(40, 54, 60, 0.7);
  border-radius: 20px;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}


.hover-brightness:hover {
  filter: brightness(105%)
}

.card-group .card {
  min-width: 120px;
}

section {
  width: 100%;
  margin-bottom: 40px;
  padding: 20px;
}
</style>
