<template>
  <section id="about" style="margin: 30px 0; display: flex">
    <div class="card" style="flex: 1">
      <div class="card-body" style="padding: 30px">
        <h4 class="sectionTitle" style="margin-bottom: 30px">About us</h4>
        <div class="row" style="display: flex">
          <div
            v-for="person in people"
            :key="person.id"
            class="card"
            :class="[`order-${person.id}`, `order-xl-${person.order}`]"
            style="max-width: 18rem; height: 27rem; margin: 0 auto 10px"
          >
            <div class="card-body" style="">
              <div class="card-title">
                <div style="width: 100%; display: flex">
                  <img
                    :src="require('../assets/people/' + person.profilepic)"
                    :alt="person.name + ' profile photo'"
                    style="width: 80%; flex: 1"
                  />
                </div>
                <h5 style="font-weight: 600">{{ person.name }}</h5>
              </div>
              <h6 class="card-subtitle mb-2">
                {{ person.position }} |
                <a :href="'mailto:' + person.email">{{ person.email }}</a>
              </h6>
              <p class="card-text">
                <span style="font-style: italic">Background:</span>
                {{ person.background }}
              </p>
              <div style="display: flex; margin-bottom: 10px">
                <!--a
                    target="_blank"
                    :href="
                      'https://www.linkedin.com/in/' + person.linkedin + '/'
                    "
                    class="card-link btn btn-light"
                    style="flex: 1; margin: 0 15px"
                  >
                    Let’s connect on LinkedIn
                  </a-->
              </div>
            </div>
          </div>
          <div class="col order-3 order-xl-2" style="flex: 1; display: flex">
            <div class="card" style="max-width: 600px; margin: auto">
              <div class="card-body" style="padding: 30px">
                <div>
                  <h5 style="font-weight: 600">Our mission</h5>
                  <div id="missionCoreText" style="margin-bottom: 30px">
                    <div class="" style="flex: 3; padding: 10px">
                      <p>
                        is to start a people-led movement for
                        <span class="highlight"
                          >a world where everyone has full control and overview
                          over their personal data</span
                        >
                        while being able to use the internet in the simplest and
                        most convenient way.
                      </p>
                      <p>
                        The Cookie Cutter is our first demo to how
                        <span class="highlight"
                          >people-centric data handling</span
                        >
                        can increase convenience on the web. It empowers the end
                        user with more control, it raises awareness on the
                        extent of data collection, and all this without
                        sacrificing on security, in reality even strengthening
                        it.
                      </p>
                    </div>
                    <div class="" style="flex: 1; text-align: center">
                      <img
                        src="../assets/platformSchema.png"
                        alt="Illustration of the Stardust proposed infrastructure"
                        style="max-width: 200px"
                      />
                    </div>
                  </div>
                  <div id="missionMobileText">
                    <p>
                      You can read about our mission on the
                      <span class="highlight">desktop</span> version of this
                      webpage
                    </p>
                  </div>
                  <p class="text-muted">
                    If you want to help us then contact us via
                    <a
                      href="https://www.reddit.com/r/StardustForum/"
                      target="_blank"
                      >our Reddit forum</a
                    >
                    and/or donate below. Donations are currently our only source
                    of income as the Cookie Cutter is available for free.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      people: [
        {
          name: "Andrius Matšenas",
          linkedin: "andrius-matsenas",
          position: "CEO",
          email: "andrius@stardust.today",
          background:
            "Mathematics, Startups, Data science, Mathematical modelling, Design",
          profilepic: "Andrius-yellow.png",
          id: 1,
          order: 1,
        },
        {
          name: "Til Jordan",
          linkedin: "til-jordan",
          position: "CTO",
          email: "til@stardust.today",
          background:
            "Computer Science, Software development, Cloud computing, Machine learning",
          profilepic: "Til-yellow.png",
          id: 2,
          order: 3,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#missionMobileText {
  display: none;
}

@media only screen and (max-width: 600px) {
  #missionCoreText {
    display: none;
  }
  #missionMobileText {
    display: block;
  }
}
</style>
