<template>
  <article id="apple-privacy-policy">
    <h3>Pick a policy to read</h3>
    <p>Once you click a button, the result will appear here.</p>
  </article>
</template>

<script>
export default {};
</script>

<style>
</style>