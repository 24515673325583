<template>
  <section>
    <div class="container">

      <!-- Stats column -->
      <div v-if="results" id="liveStatistics">
        <h1>
          Join the <span class="highlight" style="font-weight: 600; font-family: Inter;">
            {{
              thousandUsers
            }}+
          </span> people
        </h1>
        <h3 style="margin-top: 30px; padding: 0 20px">
          If manually consenting to cookies takes 4 seconds, then our users have saved
        </h3>
        <div id="stats-container">
          <div class="time-slot">
            <span id="days" class="ticker">{{
              days
            }}</span>
            <p class="granularity">days</p>
          </div>
          <div class="time-slot">
            <span id="hours" class="ticker">{{
              hours
            }}</span>
            <p class="granularity">hours</p>
          </div>
          <div class="time-slot">
            <span id="minutes" class="ticker">{{
              minutes
            }}</span>
            <p class="granularity">minutes</p>
          </div>
        </div>
        <h3 style="margin-top: 28px">
          from just <span class="highlight">not</span> clicking on pop-ups
        </h3>
      </div>

      <!-- Video & Title -->
      <div
        style="margin: 20px 30px; max-width: 400px; min-width: 350px; display: flex; align-items: center; flex-direction: column;">
        <div class="wrapperVideo" style="
              position: relative;
              width: 100%;
              height: 0;
              padding-bottom: 100%;
            ">
          <iframe id="ytplayer" type="text/html" width="360px" height="360px" v-bind:src="
            'https://www.youtube.com/embed/' + videoID + '?autoplay=0'
          " frameborder="0" style="
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;

                border-radius: 20px;
                overflow: hidden;
              "></iframe>
        </div>
      </div>

    </div>

  </section>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      videoID: "h9U0LLr-xZY",
      results: null
    };
  },
  created() {
    fetch(
      "https://enigmatic-savannah-26783.herokuapp.com/https://api.strdst.org/counters",
      {
        method: "GET",
        withCredentials: true,
        headers: {
          "x-api-key": "rQX6p1afjc7UhkRrUdYWu9wj4kXIX2BT7tC19RNV",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => (this.results = data))
      .catch((err) => console.log(err.message));
  },
  computed: {

    days() {
      return Math.floor((this.results.popups * 4) / 60 / 60 / 24);
    },
    hours() {
      return Math.floor(((this.results.popups * 4) / 60 / 60) % 24);
    },
    minutes() {
      return Math.floor(((this.results.popups * 4) / 60) % 60);
    },
    seconds() {
      return Math.floor((this.results.popups * 4) % 60);
    },
    thousandUsers() {
      return Math.floor(this.results.users / 100) * 100;
    },
  },
};
</script>

<style scoped>
section {
  background-color: rgba(40, 54, 60, 0.7);
  backdrop-filter: blur(7px);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

div.container {
  padding: 30px;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: center;
}

h1 {
  max-width: 595px;
  /* 2-lines of text at widest */
}

#liveStatistics {
  text-align: center;
  max-width: 585px;
}

#stats-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 20px 0;
}

.time-slot {
  font-family: Inter;
  background-color: #182226;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ticker {
  font-size: 60px;
  font-weight: 700;

}

.granularity {
  font-size: 20px;
  font-weight: 600;
  color: #e8ae1b;
}

.btn.btn-link {
  text-decoration: none;
  color: #fefefe;
}
</style>