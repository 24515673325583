<template>
  <section>
    <div class="container">
      <GithubButton />
      <a href="/contribute#donate" class="btn-yellow">
        Donate to Stardust
      </a>
      <ProductHuntButton />
    </div>

  </section>
</template>

<script>
import ProductHuntButton from "./ProductHuntButton.vue";
import GithubButton from "./GithubButton.vue";

export default {
  components: {
    ProductHuntButton,
    GithubButton
  },
}
</script>

<style lang="css" scoped>
div.container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 20px auto;
  flex-wrap: wrap;
}

.btn-yellow {
  margin: 10px;
}
</style>