<template>
  <article id="notFound">
    <div style="display: flex; margin-top: 30px">
      <div class="card" style="flex: 1">
        <div class="card-body" style="padding: 30px">
          <button @click="redirectHome" class="btn btn-yellow" style="position: relative; display: inline; float: right;">
            Back home
          </button>
          <h2 style="font-weight: 700">Policies</h2>

          <p style="text-align: left; margin: 0 auto 10px">
            Choose a policy to read:
          </p>
          <div
            id="policyOptions"
            style="display: flex; justify-content: center;"
          >
            <router-link
              v-for="policy in policies"
              :to="'/policies/' + policy.id"
              :key="policy.id"
              class="btn btn-dark btn-policy"
              style="margin: 10px"
              ><span class="btn-policy-inner">
                {{ policy.title }}
                <span
                  style="
                    display: block;
                    font-size: 0.8rem;
                    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
                  "
                >
                  {{ policy.subtitle }}
                </span>
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex">
      <div class="card" style="flex: 1; margin-top: -10px">
        <div class="card-body" style="padding: 30px">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <NewsFeatures />
    <AboutUs />
  </article>
</template>

<script>
import NewsFeatures from "../../components/NewsFeatures.vue";
import AboutUs from "../../components/AboutUs.vue";

export default {
  components: {
    NewsFeatures,
    AboutUs,
  },
  data() {
    return {
      routeId: this.$route.fullPath.substring(10),
      policies: [
        {
          title: "Privacy Policy ",
          subtitle: "(website)",
          id: "website-privacy-policy",
          html: "<p>website priv pol</p>",
        },
        {
          title: "Cookie Policy ",
          subtitle: "(website)",
          id: "website-cookie-policy",
          html: "<p>website priv pol</p>",
        },
        {
          title: "Privacy Policy ",
          subtitle: "(macOS & iOS app)",
          id: "apple-privacy-policy",
          html: "<p>website priv pol</p>",
        },
      ],
    };
  },
  methods: {
    redirectHome() {
      this.$router.push({ name: "Cookie Cutter" });
    },
  },
};
</script>

<style>
.last-update {
  font-size: 0.9rem;
}
.emphasis {
  font-weight: 700;
}
.btn-policy {
  padding: 10px 50px;
  background-color: #666f70;
  font-weight: 600;
  border-radius: 20px;
  border: none;
}
.btn-policy:hover {
  background-color: #818f91;
  font-weight: 600;
}
.btn-policy.router-link-active {
  background-color: #e8ae1b;
}
.btn-policy .btn-policy-inner {
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
}

#policyOptions {
  flex-direction: row;
}

@media only screen and (max-width: 800px) {
  #policyOptions {
    flex-direction: column;
  }
}
</style>