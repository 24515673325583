<template>
  <div class="card" style="width: 290px">
    <div class="card-body">
      <h5 class="card-title">Annual Supporter</h5>
      <h6 class="card-subtitle mb-2 text-muted">
        10€ yearly recurring donation
      </h6>
      <div class="card-text" style="margin: 20px 0; display: flex; flex-direction: column; justify-content: center;">
        <div style="height: 150px;">
          <p class="highlight" style="margin-bottom: 3px">
            Does the Cookie Cutter constantly make your day better?
          </p>
          <p>
            If you value the long-term benefit, please consider becoming a recurring
            supporter!
          </p>
        </div>
        <StripeCheckout ref="checkoutRef" mode="subscription" :pk="publishableKey" :line-items="lineItems"
          :success-url="successURL" :cancel-url="cancelURL" @loading="(v) => (loading = v)" />

        <button @click="submit" class="btn btn-light btn-donate">
          <span style="
                filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
                font-weight: 600;
              ">{{ checkoutText }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  name: "DonateFiatRecurring",
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey =
      "pk_live_51IIhdlAUukCZ1EKpzH7xB4uRNW3JVbIbXmestbNEhvvRK0wXXFAi8BvICRdyp6WtRJAINuKMBzxiKLii4ID3eRR000jB4LwpwK";
    return {
      checkoutText: "Donate Annual",
      loading: false,
      lineItems: [
        {
          price: "price_1JlYcyAUukCZ1EKpfiZmFOrB", // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: "https://www.stardustnetwork.com/success",
      cancelURL: "https://www.stardustnetwork.com/cancel",
    };
  },
  methods: {
    submit() {
      this.checkoutText = "Loading...";
      setTimeout(() => (this.checkoutText = "Choose Annual"), 10000);
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  margin: 0;
}
</style>
