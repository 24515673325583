<template>
  <div style="
        display: flex;
        background-color: #e8ae1b;
        border-radius: 20px;
        width: 100%;
        -webkit-filter: drop-shadow(0px 0px 6px #182226);
  filter: drop-shadow(0px 0px 6px #182226);
      ">
    <img :src="require('../../assets/browserLogos/' + image)" :alt="name + ' logo'"
      style="height: 70px; margin: auto 0" />
    <div style="
          flex: 1;
          margin: auto 0;
          filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
        ">
      <p style="
            padding: 0;
            margin-bottom: -3px;
            margin-top: 5px;
            font-weight: 600;
            font-size: 16px;
          ">
        Get it for
      </p>
      <p style="
            padding: 0;
            margin: 0;
            margin-bottom: 7px;
            padding-right: 8px;
            font-weight: 700;
            font-size: 25px;
            line-height: 1.5rem;
          ">
        {{ name }}
      </p>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    name: String,
    image: String,
  },
};
</script>

<style>
</style>