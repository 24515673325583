<template>
  <article id="website-privacy-policy">
    <h3>Privacy Policy for this website</h3>

    <p class="last-update text-muted">Last updated: March 14, 2022</p>

    <p>
      This website does not collect any indentifying or potentially identifiable
      data directly from you. We use third party services to fulfill some
      services, e.g. Stripe for receiving donations. In those cases, please
      refer to their privacy policies and terms & conditions, usually found via
      link when interacting with the service.
    </p>

    <p>
      We use data to provide and improve our website services. This website does
      use measuring services such as Google Analytics, which provides us with
      anonymised and aggregated data. Please see our Cookie Policy for info on
      what data is collected using cookies.
    </p>

    <p>Any data will not be sold to third-parties.</p>

    <h4>Contact Us</h4>
    <p id="article-end">
      If you have any questions about this Privacy Policy, You can contact us by
      email: <a href="mailto:hey@strdst.org">hey@strdst.org</a>
    </p>

    <p class="text-muted">
      Stardust Network OÜ, reg.
      <a
        href="https://ariregister.rik.ee/eng/company/16032321/Stardust-Network-OÜ"
        target="_blank"
        >16032321</a
      >
      , Narva mnt 26, Tallinn, 10120.
    </p>
  </article>
</template>

<script>
export default {};
</script>

<style>
</style>