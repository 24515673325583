import { createRouter, createWebHistory } from 'vue-router';
import CookieCutter from '../views/CookieCutter.vue';
import Platform from '../views/Platform.vue';
import NotFound from '../views/NotFound.vue';
import Success from '../views/Success.vue';
import Policies from '../views/Policies/Policies.vue';
import WPrivacy from "../views/Policies/WPrivacy.vue";
import WCookie from "../views/Policies/WCookie.vue";
import APrivacy from "../views/Policies/APrivacy.vue";
import PDefault from "../views/Policies/PDefault.vue";
import Contribute from '../views/Contribute.vue';

const routes = [
  {
    path: '/',
    name: 'Cookie Cutter',
    component: CookieCutter
  },

  {
    path: '/platform',
    name: 'Platform',
    component: Platform
  },

  {
    path: '/contribute',
    name: 'Contribute',
    component: Contribute
  },

  {
    path: '/success',
    name: 'Success',
    component: Success
  },

  {
    path: '/policies',
    // You could also have named views at the top
    component: Policies,
    children: [{
      path: 'website-privacy-policy',
      component: WPrivacy
    }, {
      path: 'website-cookie-policy',
      components: {
        default: WCookie
      }
    },
    {
      path: 'apple-privacy-policy',
      components: {
        default: APrivacy
      }
    },
    {
      path: '',
      component: PDefault
    }]
  },

  //redirects
  {
    path: '/cancel',
    redirect: '/'
  },

  //catcherror
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router