<template>
  <section id="opensource">
    <div class="container">
      <h1>Contribute with code</h1>

      <p style="max-width: 700px;">
        See here all Cookie Cutter repositories:
      </p>

      <!-- All repo panels -->
      <div style="display: flex; justify-content: center; flex-wrap: wrap;">
        <!-- Core -->
        <div class="card" style="width: 290px">
          <div class="card-body">
            <h5 class="card-title">stardust_core</h5>
            <h6 class="card-subtitle mb-2 text-muted">Logic of Cookie Cutter</h6>
            <div class="card-text"
              style="margin: 20px 0; display: flex; flex-direction: column; justify-content: center;">
              <div style="height: 150px;">
                <p style="margin-bottom: 3px">
                  This repository includes the source code of our <a
                    href="https://www.npmjs.com/package/stardust_cookie_cutter_core" target="_blank">npm-package</a>
                  that runs the logic of the Cookie Cutter
                </p>
              </div>


              <button onclick=" window.open('https://github.com/strdst-org/stardust_core','_blank')"
                class="btn btn-yellow">
                Github
              </button>

            </div>
          </div>
        </div>

        <!-- Extension -->
        <div class="card" style="width: 290px">
          <div class="card-body">
            <h5 class="card-title">Stardust_Cookie_Cutter</h5>
            <h6 class="card-subtitle mb-2 text-muted">Cookie Cutter Chrome/Firefox</h6>
            <div class="card-text"
              style="margin: 20px 0; display: flex; flex-direction: column; justify-content: center;">
              <div style="height: 150px;">
                <p style="margin-bottom: 3px">
                  This repository holds the whole extension that can be built for Chromium (includes Chrome, Opera,
                  Brave, Edge)
                  and for Firefox browsers.
                </p>
              </div>
              <button class="btn btn-secondary">
                Coming soon
              </button>
            </div>
          </div>
        </div>
      </div>

      <p style="max-width: 700px;">Not sure how or what to work on? Join our <a href="https://discord.gg/Ssh9faHbSu"
          target="_blank">Discord channel</a>
        or start a thread on our <a href="https://www.reddit.com/r/StardustForum/" target="_blank">Reddit Forum</a>.
        <br>
        If you’re an avid user, please consider donating below.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "OpenSource"
}
</script>

<style scoped>
section {
  background-color: rgba(40, 54, 60, 0.7);
  backdrop-filter: blur(7px);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  max-width: 595px;
  /* 2-lines of text at widest */
  margin-left: auto;
  margin-right: auto;
}
</style>