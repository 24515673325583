<template>
  <article id="CookieCutter">
    <WelcomeSection />
    <LinkSection />
    <StatsVideoSection />
    <!-- <AboutUs />
    <Donate />
    <NewsFeatures /> -->
  </article>
</template>

<script>
import WelcomeSection from "../components/CookieCutter/WelcomeSection.vue";
import LinkSection from "../components/CookieCutter/LinkSection.vue";
import StatsVideoSection from "../components/CookieCutter/StatsVideoSection.vue";
// import NewsFeatures from "../components/NewsFeatures.vue";
// import AboutUs from "../components/AboutUs.vue";
// import Donate from "../components/Donate/Donate.vue";

export default {
  name: "CookieCutter",
  components: {
    WelcomeSection,
    LinkSection,
    StatsVideoSection,
    // NewsFeatures,
    // AboutUs,
    // Donate,
  },
};
</script>
