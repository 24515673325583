<template>
  <article id="apple-privacy-policy">
    <h3>Privacy Policy for Stardust Cookie Cutter iOS and macOS app</h3>
    <p class="last-update text-muted">Last updated: March 14, 2022</p>
    <p>
      This Privacy Policy describes Stardust’s (We/Us) policies and procedures
      on the collection, use and disclosure of users data.
    </p>
    <p class="emphasis">We don't store your personal data, period.</p>
    <p>
      The Stardust Cookie Cutter apps for iOS and macOS devices are free
      applications that store all data locally on your device. This is also
      noted by "Data Not Collected. The developer does not collect any data from
      this app."- label that can be seen on Stardust Cookie Cutter
      <a
        href="https://apps.apple.com/ee/developer/stardust-network-ou/id1525565261"
        target="_blank"
        >Apple App Store</a
      >
      product page.
    </p>

    <h4>Reported websites</h4>
    <p>
      The application offers functionality to voluntarily report webpages.
      Either if the website does not seem to comply with regulations or if the
      Cookie Cutter application is not working properly. These websites will be
      sent to us from an anonymised and randomly generated user identifier and
      cannot be associated with You or your browsing habits/patterns.
    </p>

    <h4>Contact Us</h4>
    <p id="article-end">
      If you have any questions about this Privacy Policy, You can contact us by
      email: <a href="mailto:hey@strdst.org">hey@strdst.org</a>
    </p>

    <p class="text-muted">
      Stardust Network OÜ, reg.
      <a
        href="https://ariregister.rik.ee/eng/company/16032321/Stardust-Network-OÜ"
        target="_blank"
        >16032321</a
      >
      , Narva mnt 26, Tallinn, 10120.
    </p>
  </article>
</template>

<script>
export default {};
</script>

<style>
</style>