<template>
  <a href="https://www.producthunt.com/posts/stardust-cookie-cutter?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-stardust-cookie-cutter"
     target="_blank" height="54" class="hover-brightness">
    <img
      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=294208&theme=dark"
      alt="Stardust Cookie Cutter featured on Product Hunt"
      width="250"
      height="54"
      style="
        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
        margin: 10px 0;
      "
    />
  </a>
</template>
    
<script>
  import githubtext from "../../assets/githubLogo/GitHub_Logo.png"
  import githublogo from "../../assets/githubLogo/GitHub-Mark-120px-plus.png"

  export default {
      data: function () {
          return {
              githubtext: githubtext,
              githublogo: githublogo
          }
      }
  }
</script>
    
<style lang="css">
.btn-white {
  background-color: #fff;
  color: #000;
  font-size: 20px;
  text-decoration: none;
  padding: 10px 30px;
  font-weight: 600;
  border-radius: 10px;
  height: 55px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

.btn-white:hover {
  color: #000;
  filter: brightness(105%);
  filter: drop-shadow(0 4px 4px rgba(44, 44, 44, 0.25));
}
</style>