<template>
  <section id="donate" style="margin: 30px 0; display: flex">
    <div class="container">
      <h1 class="sectionTitle" style="margin-bottom: 20px">
        Please consider donating
      </h1>

      <div id="donation-slots">
        <p id="donate-desc">
          Stardust Cookie Cutter is made by Stardust Network OÜ. <br><br> We are keeping this project transparent and
          free by making the source code public and collecting donations from avid and supporting users. <br><br>
          You can support our work with a donation from the choices below. This helps us build more tools to empower
          people with control over their personal data.
        </p>

        <div id="fiat">

          <DonateRecurringProduct class="donation-option" />
          <DonateOneTime class="donation-option" />

        </div>

        <DonateCrypto />
        <p>If you’d like to propose a custom donation, please get in touch at <a
            href="mailto:hey@strdst.org">hey@strdst.org</a></p>
      </div>
    </div>
  </section>
</template>

<script>

import DonateRecurringProduct from "./DonateRecurringProduct.vue";
import DonateOneTime from "./DonateOneTime.vue";
import DonateCrypto from "./DonateCrypto.vue";

export default {
  name: "Donate",
  components: {
    DonateRecurringProduct,
    DonateOneTime,
    DonateCrypto,
  },
};
</script>

<style scoped>
section {
  background-color: rgba(40, 54, 60, 0.7);
  backdrop-filter: blur(7px);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

.donation-option {
  margin: 10px;
}

div#donation-slots {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

}

div#fiat {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

p#donate-desc {
  max-width: 500px;
}
</style>