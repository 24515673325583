<template>
  <div id="more" class="footer">

    <!-- Footer content -->
    <div class="container">

      <!-- Title row -->
      <div class="row">
        <div class="col-md-7">
          <h2 style="margin: 20px;">
            <span class="stardust-title">Stardust</span> makes transparent tools to give people control of their
            personal data on the web
          </h2>
        </div>
        <div class="col-md-5" style="display: flex; justify-content: center; align-items: center;">
          <a href="/contribute" class="btn-yellow">
            Contribute
          </a>
        </div>
      </div>

      <hr>

      <!-- Second row -->
      <div class="row" style="margin-bottom: 40px">

        <!-- Link section -->
        <div class="col-lg-7" style="display: flex; justify-content: space-around; flex-wrap: wrap;">

          <!-- Email support -->
          <div style="margin: 5px" id="available-email">
            <p style="margin: 0;">
              For anything constructive:
            </p>
            <a href="mailto:hey@strdst.org" class="footerLinks">hey@strdst.org</a>
          </div>

          <!-- Reddit forum -->
          <div style="margin: 5px" id="reddit-link">
            <p style="margin: 0;">
              Feedback, ideas and FAQ:
            </p>
            <a href="https://www.reddit.com/r/StardustForum/" class="footerLinks" target="_blank">Stardust Forum on
              Reddit</a>
          </div>

          <!-- Discord -->
          <div style="margin: 5px" id="discord-link">
            <p style="margin: 0;">
              Community &amp; communications:
            </p>
            <a href="https://discord.gg/Ssh9faHbSu" class="footerLinks" target="_blank">Stardust Discord channel</a>
          </div>


        </div>

        <!-- Policy link section -->
        <div class="col-lg-5" style="display: flex">
          <div style="margin: 5px auto">
            <p style="margin: 0">Read our short & clear policies:</p>
            <a href="/policies/website-privacy-policy" class="footerLinks">Privacy Policy</a>
            <a href="/policies/website-cookie-policy" class="footerLinks">Cookie Policy</a>
          </div>
        </div>
      </div>
    </div>


    <p style="text-align: center" class="text-muted">
      © 2022 Stardust Network OÜ (reg. 16032321)<br>
      Tallinn, Estonia
    </p>
  </div>
</template>

<script>
export default {
  name: "More",
  props: {
    msg: String,
  },
  data() {
    return {
      moreText: "More ◂",
    };
  },
  methods: {
    //More and Less button at the bottom of the page
    moreClick() {
      if (this.moreText == "More ◂") {
        this.moreText = "Less ▾";
      } else {
        this.moreText = "More ◂";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  padding: 2rem 0 5px;
  background-color: rgba(40, 54, 60, 0.7);
  backdrop-filter: blur(7px);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

.footerLinks {
  margin-right: 10px;
}
</style>
