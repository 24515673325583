<template>
  <article id="Platform">
    <h1>Stardust Platform</h1>
    <NewsFeatures />
    <Donate />
  </article>
</template>

<script>
import NewsFeatures from "../components/NewsFeatures.vue";
import Donate from "../components/Donate/Donate.vue";

export default {
  name: "Platform",
  components: {
    NewsFeatures,
    Donate,
  },
};
</script>