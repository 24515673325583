<template>
  <article id="Contribute">
    <OpenSource />
    <Donate />
    <NewsFeatures />
  </article>
</template>

<script>
import OpenSource from "../components/OpenSource.vue";
import Donate from "../components/Donate/Donate.vue";
import NewsFeatures from "../components/NewsFeatures.vue";

export default {
  name: "Platform",
  components: {
    OpenSource,
    Donate,
    NewsFeatures,
  },
};
</script>