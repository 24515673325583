<template>
  <a href="https://github.com/strdst-org/stardust_core" class="hover-brightness" target="_blank"
    style="text-decoration: none;">
    <div class="btn-white" id="button-whole"
      style="margin: 10px 0; filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));">
      <img :src="githublogo" style="height: 35px;" />
      <div style="position: relative; margin-left: 3px;">
        <p id="githubtext">OPEN SOURCE ON</p>
        <img :src="githubtext" style="height: 35px; position: absolute; top: 6px;" />
      </div>
    </div>
  </a>
</template>
    
<script>
import githubtext from "../../assets/githubLogo/GitHub_Logo.png"
import githublogo from "../../assets/githubLogo/GitHub-Mark-120px-plus.png"

export default {
  data: function () {
    return {
      githubtext: githubtext,
      githublogo: githublogo
    }
  }
}
</script>
    
<style lang="css">
.btn-white {
  background-color: #fff;
  color: #000;
  font-size: 20px;
  text-decoration: none;
  padding: 10px 30px;
  font-weight: 600;
  border-radius: 10px;
  height: 54px;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

.btn-white:hover {
  color: #000;
  filter: brightness(105%);
  filter: drop-shadow(0 4px 4px rgba(44, 44, 44, 0.25));
}

#githubtext {
  margin: 0 0 0 5px;
  padding: 0;
  position: absolute;
  top: -3px;
  font-size: 11px;
  font-family: Inter;
  font-weight: 700;
}

#button-whole {
  display: flex;
  flex-direction: row;

  -webkit-filter: drop-shadow(0px 0px 6px #182226);
  filter: drop-shadow(0px 0px 6px #182226);
}
</style>