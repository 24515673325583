<template>
  <section id="News" style="margin: 30px 0; display: flex">
    <div class="container">
      <h1 class="sectionTitle">Articles covering Stardust</h1>
      <!-- News article cards-->
      <div class="card-group" style="display: flex; flex-wrap: wrap">
        <div class="card" v-for="article in articles" :key="article.id" style="
              overflow: hidden;
              padding: 0;
              flex: 1;
              min-width: 200px;
              cursor: pointer;
            ">
          <a :href="article.url" target="_blank" style="all: unset">
            <img class="card-img-top" :src="article.img" :alt="'Thumbnail image for article called' + article.name"
              style="height: 200px; overflow: hidden; object-fit: cover" />
            <div class="card-body">
              <h5 class="card-title" style="font-weight: 600">
                {{ article.title }}
              </h5>
              <p class="card-subtitle text-muted mb-2">
                by {{ article.author }} on {{ article.publication }}
              </p>
              <div class="card-text">
                Article language: {{ article.lang[1] }} <br />
                Paywall: {{ article.paywall ? "Yes" : "No" }}
              </div>
            </div>
            <hr style="margin: 0" />
            <div style="padding: 10px; padding-bottom: 15px">
              <small class="text-muted">Posted
                {{
                  article.releaseMonth +
                    " " +
                    article.releaseDay +
                    " " +
                    article.releaseYear
                }}</small>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NewsFeatures",
  data() {
    return {
      articles: [
        {
          id: 1,
          title: "Isikuandmete Netflix leevendab 'küpsiste' hirmu",
          author: "Johanna Adojaan",
          publication: "foundME",
          releaseDay: "22",
          releaseMonth: "September",
          releaseYear: "2021",
          url: "https://www.foundme.io/uudised/2021/09/20/isikuandmete-netflix-leevendab-kupsiste-hirmu",
          img: "https://static-img.aripaev.ee/?type=preview&uuid=053c72d2-67cd-5dcd-9965-1770857ba05a&width=2000&q=85",
          lang: ["en", "🇪🇪"],
          paywall: true,
        },
        {
          id: 2,
          title: "A new era of data privacy",
          author: "Til Jordan (with GPT-3)",
          publication: "Medium",
          releaseDay: "30",
          releaseMonth: "July",
          releaseYear: "2021",
          url: "https://medium.com/@tiljrd/a-new-era-of-data-privacy-d45ae7bd1dcb",
          img: "https://miro.medium.com/max/1400/1*Nfuf_XFB6whLYOpuZA9t2w.jpeg",
          lang: ["en", "🇺🇸🇬🇧"],
          paywall: false,
        },
        {
          id: 3,
          title: "Stardust launches time saving ‘Cookie Cutter’",
          author: "Alex Dunlop",
          publication: "Future Worlds",
          releaseDay: "31",
          releaseMonth: "March",
          releaseYear: "2021",
          url: "https://futureworlds.com/stardust-launches-time-saving-cookie-cutter/",
          img: "https://futureworlds.com/wp-content/uploads/2021/03/16-9v1-1024x576.jpg",
          lang: ["en", "🇺🇸🇬🇧"],
          paywall: false,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

.card {

  backdrop-filter: blur(7px);
}
</style>
