<template>
  <div id="wrapper">
    <img src="../../assets/illustrations/overview-screen.svg" id="overview-screen">
    <img src="../../assets/illustrations/main-screen.svg" id="main-screen">
  </div>
</template>

<script setup>

</script>

<style lang="css" scoped>
#wrapper {
  position: relative;
  height: 485px;
  width: 345px;
}

img {
  position: absolute;
  border-radius: 10px;
  height: 400px;

  box-shadow: 10px -10px 40px 0.05px #f1f1f11f;
  /* in order: x offset, y offset, blur size, spread size, color */
}

#main-screen {
  left: 100px;
  top: 85px;
}

#overview-screen {
  left: 0;
  top: 0;
}
</style>