<template>
  <article id="notFound">
    <div style="display: flex; margin-top: 30px">
      <div class="card" style="flex: 1; text-align: center">
        <div class="card-body" style="padding: 30px">
          <h2 style="font-weight: 700">404</h2>
          <h3 style="margin-bottom: 50px">Page not found!</h3>
          <button @click="redirect" class="btn btn-light">
            Back to main screen
          </button>
        </div>
      </div>
    </div>
    <NewsFeatures />
    <AboutUs />
  </article>
</template>

<script>
import NewsFeatures from "../components/NewsFeatures.vue";
import AboutUs from "../components/AboutUs.vue";

export default {
  components: {
    NewsFeatures,
    AboutUs,
  },
  methods: {
    redirect() {
      this.$router.push({ name: "Cookie Cutter" });
    },
  },
};
</script>
