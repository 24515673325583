<template>
  <article id="Donation success">
    <div style="display: flex; margin-top: 30px">
      <div class="card" style="flex: 1">
        <div class="card-body" style="padding: 30px">
          <h3>
            <span class="highlight">Thank you so much</span> for believing in
            our mission
          </h3>
          <p>
            We’re super glad to have you part of our community. <br />
            Our systems are not automated yet, so we’ll email you personally to
            thank for the support!
          </p>
          <button @click="redirect" class="btn btn-light">
            Back home
          </button>
        </div>
      </div>
    </div>
    <AboutUs />
  </article>
</template>

<script>
import AboutUs from "../components/AboutUs.vue";
export default {
  components: {
    AboutUs,
  },
  methods: {
    redirect() {
      this.$router.push({ name: "Cookie Cutter" });
    },
  },
};
</script>

<style>
</style>