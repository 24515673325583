<template>
  <section>
    <div class="container">

      <span id="illustration1">
        <Illustration1 />
      </span>

      <div style="max-width: 595px;">
        <h1>
          Forget cookie pop-ups and get control over your web data.
        </h1>

        <div>
          <h2>
            <span class="highlight" style="font-weight: 600;">Stardust</span> Cookie Cutter
          </h2>
          <!-- Tutorial steps -->
          <div id="tutorial">
            <div id="indent-box"></div>
            <ul style="list-style-type: none; padding: 0; margin: 20px 0;">
              <li>
                <span class="highlight" style="font-style: italic;">Step 1</span>
                <h3>
                  Install from button below
                </h3>
              </li>
              <li>
                <span class="highlight" style="font-style: italic;">Step 2</span>
                <h3>

                  Input your consents
                </h3>
              </li>
              <li>
                <span class="highlight" style="font-style: italic;">Step 3</span>
                <h3>

                  Browse with no pop-ups
                </h3>
              </li>
            </ul>
          </div>

          <!-- Text -->
          <p style="margin: 0">
            Cookie Cutter doesn’t collect personal data!<br />It’s free &amp; open-source project so please consider
            <a href="/contribute">donating</a>.
          </p>
        </div>
      </div>

      <div class="downloads">
        <ExtensionButton style="cursor: pointer; width: 280px; margin: 10px;" v-for="browser in browsers"
          :key="browser.id" :name="browser.name" :image="browser.img" @click="openURL(browser.url)" />
      </div>
    </div>
  </section>
</template>

<script>
import Illustration1 from "./Illustration1.vue";
import ExtensionButton from "./ExtensionButton.vue";

export default {
  components: {
    Illustration1,
    ExtensionButton
  },

  methods: {
    openURL(url) {
      window.open(url);
    },
  },

  computed: {
    browsers() {
      const userAgent = window.navigator.userAgent;

      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return [
          {
            name: "Safari (iPhone)",
            img: "safari.png",
            video: "uLJJqLGZxyI",
            url: "https://apps.apple.com/us/app/stardust-cookie-cutter/id1567837223",
            id: 2,
          },
        ];
      }

      return [
        {
          name: "Google Chrome",
          img: "chrome.png",
          video: "dSanh8_jJeQ",
          url: "https://chrome.google.com/webstore/detail/stardust-cookie-cutter/knliaomflpbgdhnlhomjmmoahdfhnhae",
          id: 1,
        },
        {
          name: "Apple Safari",
          img: "safari.png",
          video: "uLJJqLGZxyI",
          url: "https://apps.apple.com/us/app/stardust-cookie-cutter/id1567837223",
          id: 2,
        },
        {
          name: "Mozilla Firefox",
          img: "firefox.png",
          video: "fhQQ6UTJTR8",
          url: "https://addons.mozilla.org/addon/stardust-cookie-cutter/",
          id: 3,
        },
        {
          name: "Microsoft Edge",
          img: "edge.png",
          video: "WwylbC9CJik",
          url: "https://microsoftedge.microsoft.com/addons/detail/stardust-cookie-cutter/jhoioiejafmmjpcegggmmnaefcogejdi",
          id: 4,
        },
        {
          name: "Brave Browser",
          img: "brave.png",
          video: "uBbeme2loI",
          url: "https://chrome.google.com/webstore/detail/stardust-cookie-cutter/knliaomflpbgdhnlhomjmmoahdfhnhae",
          id: 5,
        },
        {
          name: "Opera Browser",
          img: "opera.png",
          video: "Dr9zYhY9KFo",
          url: "https://chrome.google.com/webstore/detail/stardust-cookie-cutter/knliaomflpbgdhnlhomjmmoahdfhnhae",
          id: 6,
        },
      ];
    },
  }
}
</script>

<style scoped>
section {
  background-color: rgba(40, 54, 60, 0.7);
  backdrop-filter: blur(7px);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

div.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#illustration1 {
  margin: 40px 50px;
}

h1 {
  max-width: 595px;
  /* 2-lines of text at widest */
}

li {
  display: flex;
  justify-content: flex-start
}

h3 {
  font-size: 22px;
  display: inline;
}

li span {
  font-size: 22px;
  width: 70px;
  margin-right: 10px;
}

div#tutorial {
  display: flex;
}

#indent-box {
  background-color: #e8ae1b;
  width: 2px;
  height: 100px;
  margin: 20px 0px;
  margin-right: 10px;
  margin-left: 5px;
}

@media (max-width : 400px) {
  #indent-box {
    display: none;
  }
}

div.downloads {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>