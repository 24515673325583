<template>
  <a href="/" style="text-decoration: none;">
    <div>
      <img src="../assets/logo.png" alt="Stardust logo" height="80">
      <span>
        Stardust
      </span>
    </div>
  </a>
</template>

<script setup>

</script>

<style lang="css" scoped>
div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

img {
  -webkit-filter: drop-shadow(0px 0px 6px #182226);
  filter: drop-shadow(0px 0px 6px #182226);
  margin: 0 10px;
}

span {
  color: #e8ae1b;
  font-family: Raleway;
  font-weight: 600;
  font-size: 50px;
  -webkit-filter: drop-shadow(0px 0px 6px #182226);
  filter: drop-shadow(0px 0px 6px #182226);
  margin: 0 10px;
}
</style>