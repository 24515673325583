<template>
  <div id="donateCrypto">
    <div v-for="item in items" :key="item.address" class="card" style="width: 290px;">
      <div class="card-body">

        <!-- card header -->
        <div class="card-title" style="height: 50px">
          <div style="display: flex; margin: 0 15px">
            <img :src="require('../../assets/donateCryptoAssets/' + item.logosrc)" :alt="item.name + ' logo'"
              style="height: 50px; margin: 0 12px" />
            <div style="margin: 2px; flex: 1; text-align: left; flex: 1">
              <h5 style="margin: 0">
                {{ item.name }}
              </h5>
              <p class="card-subtitle text-muted" style="padding-top: 5px">
                {{ item.subtitle }}
              </p>
            </div>
          </div>
        </div>

        <!-- QR-code -->
        <div class="card-text">
          <img :src="require('../../assets/donateCryptoAssets/' + item.qrsrc)" style="
              display: block;
              margin: 20px auto;
              height: 150px;
              border-radius: 5px;
            " />
        </div>

        <!-- address and copy button -->
        <div style="display: flex">
          <input readonly style="outline: none; padding: 7px 14px; border-radius: 10px; border: 0; flex: 1;"
            :value="item.address" />
          <span @click="copyClick(item.address)">
            <svg class="copy-btn" width="36" height="36" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
              style="margin: 2px 0 2px 10px">
              <path
                d="M12.579 2h-8.21A1.37 1.37 0 003 3.364v9.545h1.368V3.364h8.21V2zm2.053 2.727H7.105a1.37 1.37 0 00-1.368 1.364v9.545c0 .75.616 1.364 1.368 1.364h7.527A1.37 1.37 0 0016 15.636V6.091a1.37 1.37 0 00-1.368-1.364zm0 10.91H7.105V6.09h7.527v9.545z"
                fill="#f6f6f6" fill-rule="nonzero"></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DonateCrypto",
  data() {
    return {
      items: [
        {
          name: "Cardano ADA",
          logosrc: "cardano.svg",
          subtitle: "(Lowest fees)",
          qrsrc: "Cardano-QR.svg",
          id: "fullADA",
          address:
            "addr1q92pdkrycdsn7j6sktvx8jt7udruwx4m2p7f6hmpu7rulslvxr06lgyqm398pscvhwyp7zuk0xwapcemfrqs2sn4du0qw6xy3w",
        },
        {
          name: "Ethereum ETH",
          logosrc: "ethereum.svg",
          subtitle: "",
          qrsrc: "Ethereum-QR.svg",
          id: "fullETH",
          address: "0xD93ebE259747f93aABC7E77a5Cf5769A76fF8599",
        },
        {
          name: "Bitcoin BTC",
          logosrc: "bitcoin.svg",
          subtitle: "(min. ₿0.00010)",
          qrsrc: "Bitcoin-QR.svg",
          id: "fullBTC",
          address: "3DkcNF7J8W9FzYGUwcKEbRKj5hwkiWb8rk",
        },
      ],
    };
  },
  methods: {
    copyClick(addr) {
      console.log(addr);
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = addr;
      dummy.select();
      dummy.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand("copy");
      document.body.removeChild(dummy);
      /* Alert the copied text */
      alert("Copied to your clipboard: " + addr);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div#donateCrypto {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

#cryptoUl {
  list-style-type: none;
  padding: 0;
  display: flex;
}

li {
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .card {
    margin: 7px 0;
  }
}
</style>
